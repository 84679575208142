import React, { useState, useEffect, useRef } from 'react';
import { Analytics } from '@vercel/analytics/react';
import CreditPurchaseModal from './CreditPurchaseModal';
import ReactMarkdown from 'react-markdown';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles.css';

import { initializeApp } from 'firebase/app';
import {
  getAuth,
  GoogleAuthProvider,
  onAuthStateChanged,
  signInWithPopup,
  signOut
} from 'firebase/auth';

import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
  onSnapshot
} from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyDYN40xFtUfQUWV3MSg3N6BubppdThmAUw",
  authDomain: "bluey-ecb71.firebaseapp.com",
  projectId: "bluey-ecb71",
  storageBucket: "bluey-ecb71.firebasestorage.app",
  messagingSenderId: "722046019918",
  appId: "1:722046019918:web:7b6037414e437caaded61f",
  measurementId: "G-DR6ZQW9HDH"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
const db = getFirestore(app);

function App() {
  const [inputValue, setInputValue] = useState('');
  const [conversation, setConversation] = useState([
    { role: 'system', content: 'You are a Bluebook citation AI assistant. Help the user generate a citation based on the information they provide.' },
  ]);
  const [chatStarted, setChatStarted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [user, setUser] = useState(null);
  const [authLoading, setAuthLoading] = useState(true);
  const [pendingStart, setPendingStart] = useState(false);
  const [credits, setCredits] = useState(null);

  const [showModal, setShowModal] = useState(false);

  const initialInputRef = useRef(null);
  const chatInputRef = useRef(null);
  const chatContainerRef = useRef(null);

  const exampleQuestions = [
    "How do I cite a USPTO office action?",
    "Is the second comment in see eg italicized?",
    "Cite Mcculloch v Maryland",
    "If I am citing to two cases as direct support for a claim, and one additional case as informative but indirect support for the claim, what signal do I use for the two sets of sources?",
    "If I'm citing three different cases - one from a state trial court, one from a federal trial court, and one from the US Supreme Court, in what order do they fall?",
    "Cite Public law 94-14"
  ];

  useEffect(() => {
    let unsubscribeSnapshot = null;

    const handleAuthStateChanged = (currentUser) => {
      setUser(currentUser);
      setAuthLoading(false);

      if (currentUser) {
        const userDocRef = doc(db, 'users', currentUser.uid);
        
        // Fetch user document
        getDoc(userDocRef).then((userDocSnap) => {
          if (!userDocSnap.exists()) {
            // Initialize with 5 credits if user document doesn't exist
            return setDoc(userDocRef, { credits: 5 });
          }
          return null;
        }).then(() => {
          // Set up real-time listener after ensuring user document exists
          unsubscribeSnapshot = onSnapshot(userDocRef, (snapshot) => {
            if (snapshot.exists()) {
              setCredits(snapshot.data().credits);
            }
          });
        }).catch(error => {
          console.error("Error initializing user credits:", error);
        });
      } else {
        setCredits(null);
        if (unsubscribeSnapshot) {
          unsubscribeSnapshot();
          unsubscribeSnapshot = null;
        }
      }
    };

    const unsubscribeAuth = onAuthStateChanged(auth, handleAuthStateChanged);

    return () => {
      unsubscribeAuth();
      if (unsubscribeSnapshot) {
        unsubscribeSnapshot();
      }
    };
  }, [auth, db]);

  // New useEffect to handle pendingStart after credits are loaded
  useEffect(() => {
    if (pendingStart && user && credits !== null) {
      if (credits > 0) {
        // Only proceed if user has credits
        setPendingStart(false);
        handleSend();
      } else {
        // If user has no credits, prompt to buy
        alert('You have no credits left. Please purchase more credits to continue.');
        setPendingStart(false);
      }
    }
  }, [pendingStart, user, credits]);

  const handleSend = async () => {
    if (!user) {
      if (!chatStarted && inputValue.trim()) {
        setPendingStart(true);
        handleSignIn();
      }
      return; 
    }

    if (!inputValue.trim()) return;

    if (credits === null) {
      // Credits are still loading
      alert('Loading your credits. Please wait a moment and try again.');
      return;
    }

    if (credits <= 0) {
      alert('You have no credits left. Please purchase more credits to continue.');
      return;
    }

    try {
      const userDocRef = doc(db, 'users', user.uid);
      const userDocSnap = await getDoc(userDocRef);
      const userData = userDocSnap.data();

      if (!userData || userData.credits <= 0) {
        alert('You have no credits left. Please purchase more credits to continue.');
        return;
      }

      // Deduct one credit
      await setDoc(userDocRef, { credits: userData.credits - 1 }, { merge: true });

      const userMessage = { role: 'user', content: inputValue.trim() };
      const updatedConversation = [...conversation, userMessage];
      setConversation(updatedConversation);
      setInputValue('');

      if (!chatStarted) {
        setChatStarted(true);
      }

      setIsLoading(true);

      const priorMessages = conversation.filter((msg) => msg.role !== 'system');
      const body = {
        user_message: userMessage.content,
        messages: priorMessages,
      };

      const response = await fetch('/api/bluebook', { 
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText || 'Network response was not ok');
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8');
      let assistantContent = '';

      const assistantMessage = { role: 'assistant', content: '' };
      const interimConversation = [...updatedConversation, assistantMessage];
      setConversation([...interimConversation]);

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        const chunk = decoder.decode(value, { stream: true });
        assistantContent += chunk;

        interimConversation[interimConversation.length - 1] = { role: 'assistant', content: assistantContent };
        setConversation([...interimConversation]);
      }

    } catch (error) {
      console.error('Error fetching AI response:', error);
      setConversation(prev => [...prev, { role: 'assistant', content: 'Sorry, something went wrong. Please try again later.' }]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClear = () => {
    setConversation([
      { role: 'system', content: 'You are a Bluebook citation AI assistant. Help the user generate a citation based on the information they provide.' },
    ]);
    setInputValue('');
    setChatStarted(false);
  };

  useEffect(() => {
    if (chatStarted && chatInputRef.current) {
      chatInputRef.current.focus();
    } else if (!chatStarted && initialInputRef.current) {
      initialInputRef.current.focus();
    }
  }, [chatStarted]);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTo({
        top: chatContainerRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, [conversation, isLoading]);

  const handleSignOut = () => {
    signOut(auth).catch(err => console.error(err));
  };

  const handleSignIn = () => {
    signInWithPopup(auth, provider).catch(err => console.error(err));
  };

  const handleOpenBuyModal = () => {
    if (!user) {
      alert('Please sign in first!');
      return;
    }
    setShowModal(true);
  };

  const handleSelectPlan = async (plan) => {
    try {
      const response = await fetch('/api/create-checkout-session', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userId: user.uid, credits: plan }),
      });
      const data = await response.json();
      if (data.url) {
        window.location.href = data.url;
      } else {
        alert('Failed to create checkout session.');
      }
    } catch (err) {
      console.error(err);
      alert('Error creating checkout session.');
    }
  };

  if (authLoading) {
    return (
      <div className="container d-flex flex-column justify-content-center align-items-center min-vh-100">
        <h2>Loading authentication...</h2>
      </div>
    );
  }

  return (
    <>
      <div className="container d-flex flex-column justify-content-center align-items-center min-vh-100" style={{ maxWidth: '800px' }}>
        {/* Header with Sign In/Out */}
        <div className="w-100 d-flex justify-content-between mb-2" style={{ position: 'fixed', top: '1rem', zIndex: 1000 }}>
          <div style={{ marginLeft: '1rem', fontWeight: 'bold' }}>
            {user && credits !== null && `Credits: ${credits}`}
          </div>
          <div>
            {user ? (
              <>
                <button className="btn btn-sm btn-outline-success mr-2" style={{ marginRight: '5px'}} onClick={handleOpenBuyModal}>
                  Buy Credits
                </button>
                <button className="btn btn-sm btn-outline-secondary" style={{ marginRight: '5px'}} onClick={handleSignOut}>
                  Sign Out ({user.displayName || user.email})
                </button>
              </>
            ) : (
              <button className="btn btn-sm btn-outline-primary" style={{ marginRight: '5px'}} onClick={handleSignIn}>
                Sign In
              </button>
            )}
          </div>
        </div>
        
        <div className="w-100 d-flex flex-column align-items-center">
          <h1 className="text-center mb-4" style={{ fontWeight: '300', fontSize: '2rem' }}>
            Bluebook Citation Generator
            <div style={{ fontSize: '1rem', color: '#6c757d', marginTop: '0.5rem' }}>
              AI model trained on legal citation rules
            </div>
          </h1>

          {/* Show examples only if chat hasn't started */}
          {!chatStarted && (
            <div className="mb-4 w-100" style={{ maxWidth: '600px', overflow: 'hidden' }}>
              <div className="scroll-container">
                <div className="marquee-content">
                  {/* Repeat the questions for seamless scroll */}
                  {exampleQuestions.map((question, idx) => (
                    <div key={`first-${idx}`} onClick={() => setInputValue(question)} style={{ cursor: 'pointer', margin: '0.5rem 0' }}>
                      {question}
                    </div>
                  ))}
                  {exampleQuestions.map((question, idx) => (
                    <div key={`second-${idx}`} onClick={() => setInputValue(question)} style={{ cursor: 'pointer', margin: '0.5rem 0' }}>
                      {question}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}


          {!chatStarted && (
            <div className="card shadow-sm border-0 w-100">
              <div className="card-body p-4">
                <div className="d-flex">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter source information..."
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onKeyDown={(e) => { if (e.key === 'Enter') handleSend(); }}
                    ref={initialInputRef}
                    aria-label="Source Information Input"
                  />
                  <button className="btn btn-primary ml-2" style={{ marginLeft: '5px' }} onClick={handleSend}>
                    Start
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>

        {chatStarted && (
          <div className="card shadow-sm border-0 w-100">
            <div className="card-body p-4 d-flex flex-column" style={{ height: '70vh' }}>
              <div
                className="mb-3 overflow-auto"
                style={{ flex: '1 1 auto', border: '1px solid #dee2e6', borderRadius: '5px', padding: '1rem', backgroundColor: '#f8f9fa' }}
                ref={chatContainerRef}
              >
                {conversation.filter((msg) => msg.role !== 'system').map((msg, index) => (
                  <div
                    key={index}
                    className={`mb-3 d-flex ${msg.role === 'assistant' ? 'justify-content-start' : 'justify-content-end'}`}
                  >
                    <div>
                      <div style={{ fontWeight: '500', marginBottom: '0.25rem' }}>
                        {msg.role === 'assistant' ? 'Bluebook AI' : 'You'}
                      </div>
                      <div
                        className={`message-bubble ${msg.role === 'assistant' ? 'bg-secondary text-white' : 'bg-primary text-white'} p-2`}
                      >
                        <ReactMarkdown>{msg.content}</ReactMarkdown>
                      </div>
                    </div>
                  </div>
                ))}
                {isLoading && (
                  <div className="d-flex align-items-center mt-2">
                    <span>Searching citation rules and generating a response...</span>
                    <div className="spinner-border spinner-border-sm text-secondary ml-2" role="status"></div>
                  </div>
                )}
              </div>
              <div className="d-flex" style={{ flexShrink: 0 }}>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Ask more questions or provide more details..."
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  onKeyDown={(e) => { if (e.key === 'Enter') handleSend(); }}
                  ref={chatInputRef}
                  aria-label="Chat Input"
                />
                <button className="btn btn-primary ml-2" onClick={handleSend}>
                  Send
                </button>
              </div>
              <div className="text-right mt-2">
                <button className="btn btn-sm btn-outline-secondary" style={{ marginLeft: '5px' }} onClick={handleClear}>
                  Clear &amp; Start Over
                </button>
              </div>
            </div>
          </div>
        )}

        <Analytics />
      </div>

      {/* Fixed Feedback Button */}
      <a
        href="https://jamesfbaker.substack.com/p/how-i-made-bluey-bot"
        target="_blank"
        rel="noopener noreferrer"
        className="feedback-button btn btn-primary"
        aria-label="Blog"
      >
        Blog
      </a>

      {/* Credit Purchase Modal */}
      <CreditPurchaseModal
        show={showModal}
        onClose={() => setShowModal(false)}
        onSelectPlan={handleSelectPlan}
      />
    </>
  );
}

export default App;
